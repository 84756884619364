import React, { useContext } from "react";
import { inputRendererMap } from "../../filter-configs/inputRendererMap";
import { REMOVE_GROUP_RULE, UPDATE_GROUP_RULE,ADD_GROUP_RULE, UPDATE_RULE } from "../../filter-state/actions";
import { queryFormContext } from "../../filter-state/context";
import { RemoveRuleBtn } from "./shared/RemoveRuleBtn";
import { AddOrClauseButton } from "./shared/AddOrClauseButton";

export const GroupRenderer = ({ config, rule, idx, widgetType }) => {
    const { dispatch } = useContext(queryFormContext);

    const handleUpdateGroup = (updatedProperties) => {
        dispatch({
            type: UPDATE_RULE,
            index: idx,
            payload: {
                properties: {
                    ...rule.properties,
                    ...updatedProperties,
                },
            },
        });
    }

    const innerHandleUpdateRule = (innerRuleIdx, updatedProperties) => {
        dispatch({
            type: UPDATE_GROUP_RULE,
            index: innerRuleIdx,
            groupIndex: idx,
            payload: {
                properties: updatedProperties,
            },
        });
    };

    return (
        <div>
            {rule.properties.value.map((v, innerRuleIdx) => {
                const Renderer = inputRendererMap[widgetType];
                return (
                    <div
                        key={`${idx}-${innerRuleIdx}`}
                        style={{ display: "flex", flexDirection: "row", alignItems: "baseline", marginBottom: "8px" }}
                    >
                        <Renderer
                            inGroup={true}
                            groupExclude={rule.properties.exclude}
                            rule={v}
                            config={config}
                            idx={innerRuleIdx}
                            handleUpdateRule={innerHandleUpdateRule}
                            handleUpdateGroup={handleUpdateGroup}
                        />
                        <AddOrClauseButton onClick={() => {
                            dispatch({
                                type: ADD_GROUP_RULE,
                                groupIndex: idx,
                                index: innerRuleIdx,
                            });
                        }} />
                        <RemoveRuleBtn onClick={() => {
                                dispatch({
                                    type: REMOVE_GROUP_RULE,
                                    index: innerRuleIdx,
                                    groupIndex: idx,
                                });
                        }} />
                    </div>
                );
            })}
        </div>
    );
};
