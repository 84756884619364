import { WidgetType } from "./widgetTypes";
import { Category } from "./categories";

export const createLabValueConfig = ({ key, units = [], defaultUnit = "", fieldInStandardUnit }) => {
    return {
        ["labs-" + key]: {
            widgetType: WidgetType.NESTED,
            category: Category.LABORATORY,
            widgetConfig: {
                label: "laboratory.timeline." + key,
            },
            defaultRuleProperties: {
                exclude: false,
                nestedPath: "labs",
                value: {
                    type: "nested",
                    nestedValues: [
                        {
                            type: "rule",
                            properties: {
                                field: "labs." + (fieldInStandardUnit || key),
                                operator: "gte",
                                value: "",
                            },
                        },
                        {
                            type: "rule",
                            properties: {
                                field: "labs." + key + "_unit",
                                virtualField: units.length > 1 ? true : false,
                                value: defaultUnit,
                            },
                        },
                        {
                            type: "rule",
                            properties: {
                                field: "labs.is_" + key + "_entry_latest",
                                value: "",
                            },
                        },
                    ],
                },
            },
        },
        ["labs." + (fieldInStandardUnit || key)]: {
            widgetType: WidgetType.SIMPLE_NUMBER,
            widgetConfig: {
                label: "",
                width: 110,
                operators: ["eq", "gt", "lt", "gte", "lte"],
            },
        },
        ["labs." + key + "_unit"]: {
            widgetType: WidgetType.DROPDOWN,
            widgetConfig: {
                label: "",
                values: units,
            },
        },
        ["labs.is_" + key + "_entry_latest"]: {
            widgetType: WidgetType.DROPDOWN,
            widgetConfig: {
                label: "latest entry",
                width: 100,
                values: [
                    { value: true, label: "global.yes" },
                    { value: false, label: "global.no" },
                ],
            },
        },
    };
};
