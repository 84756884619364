import { useContext } from "react";
import { DisableToggle } from "./searchInputRenderers/shared/DisableToggle";
import { IconButton } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { queryFormContext } from "../filter-state/context";
import { fields } from "../filter-configs/fields.conf";
import { inputRendererMap } from "../filter-configs/inputRendererMap";
import { REMOVE_RULE, TRANSFORM_RULE_TO_GROUP, UPDATE_RULE } from "../filter-state/actions";
import { Trans } from "react-i18next";
import { categoryLabels } from "../filter-configs/categories";
import {useTheme} from "@material-ui/core/styles";
import React from "react";
import { GroupRenderer } from "./searchInputRenderers/GroupRenderer";
import { RemoveRuleBtn } from "./searchInputRenderers/shared/RemoveRuleBtn";
import { Action } from "../../../components/contacts/Contacts";
import { AddOrClauseButton } from "./searchInputRenderers/shared/AddOrClauseButton";

function categorizeRootNodes(nodes) {
    return nodes.reduce((categorized, node, index) => {
        const fieldConfig = fields[node.properties.field];
        return fieldConfig
            ? !categorized[fieldConfig.category]
                ? { ...categorized, [fieldConfig.category]: [{ node, index, config: fieldConfig }] }
                : {
                      ...categorized,
                      [fieldConfig.category]: [
                          ...categorized[fieldConfig.category],
                          { node, index, config: fieldConfig },
                      ],
                  }
            : categorized;
    }, {});
}



export const FiltersByCategoryRenderer = () => {
    const { dispatch, formTree } = useContext(queryFormContext);
    const theme = useTheme();
    const categorizedNodes = categorizeRootNodes(formTree);
    const handleUpdateRule = (idx, updatedProperties) => {
        dispatch({
            type: UPDATE_RULE,
            index: idx,
            payload: {
                properties: updatedProperties,
            },
        });
    };

    return Object.entries(categorizedNodes).map(([categoryName, categoryValues]) => (
        <div
            style={{
                background: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.grey[300],
                borderRadius: "4px",
                padding: theme.spacing(1),
                marginBottom: theme.spacing(2),
            }}
            key={categoryName}
        >
            <div style={{ fontWeight: "bold", fontSize: "1.2em", marginBottom: theme.spacing(1) }}>
                <Trans i18nKey={categoryLabels[categoryName] || categoryName} />
                </div>
            {categoryValues.map((componentData, idx) => {
                const isGroupNode = componentData.node.type === "group";
                const Renderer = inputRendererMap[componentData.config.widgetType];
                return (
                    <div
                        key={idx}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        <div>
                            <DisableToggle
                                rule={componentData.node}
                                idx={componentData.index}
                                handleUpdateRule={handleUpdateRule}
                            />
                        </div>
                        {isGroupNode ? <GroupRenderer
                            config={componentData.config.widgetConfig}
                            rule={componentData.node}
                            idx={componentData.index}
                            widgetType={componentData.config.widgetType}
                        /> :
                        <>
                        <Renderer
                            config={componentData.config.widgetConfig}
                            rule={componentData.node}
                            idx={componentData.index}
                            handleUpdateRule={handleUpdateRule}
                            widgetType={componentData.config.widgetType}
                        />
                        {componentData.config.widgetConfig.allowOrCondition &&
                            <AddOrClauseButton onClick={() => {
                                dispatch({
                                    type: TRANSFORM_RULE_TO_GROUP,
                                    index: componentData.index,
                                });
                            }} />
                        }
                        <RemoveRuleBtn onClick={() => {
                                dispatch({
                                    type: REMOVE_RULE,
                                    index: componentData.index,
                                });
                        }} />
                        </>
                        }

                    </div>
                );
            })}
        </div>
    ));
};
