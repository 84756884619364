import * as Action from "./actions";
import { fields } from "../filter-configs/fields.conf";
import { defaultRulePropertiesByWidgetType } from "../filter-configs/defaultRulePropertiesByWidgetType";

function formStructureReducer(state, action) {
    switch (action.type) {
        case Action.SET_INITIAL_STATE:
            return action.payload;
        case Action.ADD_RULE:
            return [...state, action.payload];
        case Action.UPDATE_RULE:
            return state.map((rule, index) => (index === action.index ? { ...rule, ...action.payload } : rule));
        case Action.UPDATE_GROUP_RULE:
            return state.map((rule, groupIndex) =>
                groupIndex === action.groupIndex
                    ? {
                          ...rule,
                          properties: {
                              ...rule.properties,
                              value: rule.properties.value.map((innerRule, innerIndex) =>
                                  innerIndex === action.index ? { ...innerRule, ...action.payload } : innerRule,
                              ),
                          },
                      }
                    : rule,
            );
        case Action.REMOVE_GROUP_RULE:
            // If the group has only two rules, remove the rule and transform the group into a rule
            if (state[action.groupIndex].properties.value.length === 2) {
                const groupRuleToKeep = state[action.groupIndex].properties.value
                    .filter((_, innerIndex) => innerIndex !== action.index)
                    .map((rule) => ({
                        ...rule,
                        properties: { ...rule.properties, disabled: state[action.groupIndex].properties.disabled, exclude: state[action.groupIndex].properties.exclude },
                    }))[0];
                const result = state.map((node, nodeIndex) =>
                    nodeIndex === action.groupIndex ? groupRuleToKeep : node,
                );
                return result;
            } else {
                return state.map((rule, groupIndex) =>
                    groupIndex === action.groupIndex
                        ? {
                              ...rule,
                              properties: {
                                  ...rule.properties,
                                  value: rule.properties.value.filter(
                                      (innerRule, innerIndex) => innerIndex !== action.index,
                                  ),
                              },
                          }
                        : rule,
                );
            }
        case Action.ADD_GROUP_RULE:
            const firstRule = state[action.groupIndex].properties.value[0];
            const firstRuleField = firstRule.properties.field;
            const defaultProperties = getDefaultFieldProperties(firstRuleField);

            // Create the new rule with default properties
            const newRule = {
                type: "rule",
                properties: {
                    field: firstRuleField,
                    disabled: false,
                    ...defaultProperties,
                },
            };

            // Insert the new rule at the specified position (action.index)
            const updatedRules = [
                ...state[action.groupIndex].properties.value.slice(0, action.index + 1),
                newRule,
                ...state[action.groupIndex].properties.value.slice(action.index + 1),
            ];

            // Update the state with the new rules array
            return state.map((group, index) =>
                index === action.groupIndex
                    ? {
                          ...group,
                          properties: {
                              ...group.properties,
                              value: updatedRules,
                          },
                      }
                    : group,
            );
        case Action.REMOVE_RULE:
            return state.filter((_, index) => index !== action.index);
        case Action.TRANSFORM_RULE_TO_GROUP:
            return state.map((rule, index) =>
                index === action.index
                    ? {
                          type: "group",
                          properties: {
                              disabled: rule.properties.disabled,
                              exclude: rule.properties.exclude,
                              field: rule.properties.field,
                              value: [
                                  {
                                    ...rule,
                                    properties: {
                                        ...rule.properties,
                                        exclude: false,
                                    },
                                  },
                                  {
                                      type: "rule",
                                      properties: {
                                          ...getDefaultFieldProperties(rule.properties.field),
                                          field: rule.properties.field,
                                          disabled: rule.properties.disabled,
                                          exclude: false,
                                      },
                                  },
                              ],
                          },
                      }
                    : rule,
            );
        default:
            return state;
    }
}

const getRulePropertiesFromFieldsConfig = (field) => fields[field].defaultRuleProperties;

const getRulePropertiesFromWidgetType = (field) => defaultRulePropertiesByWidgetType[fields[field].widgetType];

// When you crate a new field some widgets expect particular init value structure
// But some fields may have default init properties that take precedence
// Maybe merging of configs is better so in config of fields you can specify only the properties you want to override
const getDefaultFieldProperties = (field) =>
    getRulePropertiesFromFieldsConfig(field) || getRulePropertiesFromWidgetType(field) || {};

export default formStructureReducer;
